import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";

import "./_footer.scss";
import { SpaceRow } from "../../../components/space/Space";

export default function Footer() {
    
    return(
        <Grid container>
            <div className = "container vertical-center" id = "footer" >
                <Grid container>
                    <Hidden xsDown>
                        <Grid container item xs = {12} sm = {5} direction = "column">
                            <img className = "logo" id = "header" alt = "logo" src = {require('../../../assets/images/logo/logo.png')} />
                            <div className = "flex-row margin-top-40">
                                <p className = "main-font-semi-bold font-size-14 text">© 2021 &nbsp;</p>
                                <p className = "main-font-semi-bold font-size-14 font-color-blue"> Gaelens Bouwprojecten</p>
                            </div>
                        </Grid>
                    </Hidden>
                    <Grid container item xs = {12} sm = {7}>
                        <Hidden xsDown>
                            <Grid container item sm = {4} direction = "column">
                                <p className = "main-font-semi-bold font-size-14 font-color-blue">Website</p>
                                <Link to = "/">
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-20">Onze projecten</p>
                                </Link>
                                <Link to = "/references">
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">Referentie</p>
                                </Link>
                                <Link to = "/contact">
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">Contact</p>
                                </Link>
                            </Grid>
                            <Grid container item sm = {4} direction = "column">
                                <p className = "main-font-semi-bold font-size-14 font-color-blue-grey font-color-blue">Privacy</p>
                                <Link to = "/privacy">
                                <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-20">Privacy policy</p>
                                </Link>
                                <Link to = "/disclaimer">
                                <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">Disclaimer</p>
                                </Link>
                                <Link to = "/cookies">
                                <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">Cookies settings</p>
                                </Link>
                            </Grid>
                            <Grid container item sm = {4} direction = "column">
                                <p className = "main-font-semi-bold font-size-14 font-color-blue">Gaelens NV.</p>
                                <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-20">Kortrijksesteenweg 1008/001<br></br>B-9000 Gent</p>
                                <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">+32 9 245 03 33</p>
                                <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">info@gaelensbouwprojecten.be</p>
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid container item xs = {12} direction = "column" alignItems = "center">
                                <p className = "main-font-semi-bold font-size-14 font-color-deep-blue">Website</p>
                                <Link to = "/">
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-20">Onze projecten</p>
                                </Link>
                                <Link to = "/references">
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">Referentie</p>
                                </Link>
                                <Link to = "/contact">
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">Contact</p>
                                </Link>
                            </Grid>
                            <Grid container item xs = {12} direction = "column" alignItems = "center">
                                <p className = "main-font-semi-bold font-size-14 font-color-deep-blue font-color-blue margin-top-13">Privacy</p>
                                <Link to = "/privacy">
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-20">Privacy policy</p>
                                </Link>
                                <Link to = "/disclaimer">
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">Disclaimer</p>
                                </Link>
                                <Link to = "/privacy">
                                    <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">Cookies settings</p>
                                </Link>
                            </Grid>
                            <Grid container item xs = {12} direction = "column" alignItems = "center">
                                <p className = "main-font-semi-bold font-size-14 font-color-deep-blue margin-top-13">Gaelens NV.</p>
                                <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-20 center-text">Kortrijksesteenweg 1008/001<br></br>B-9000 Gent</p>
                                <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">+32 9 245 03 33</p>
                                <p className = "main-font-regular font-size-14 font-color-blue-grey text cursor-pointer margin-top-7">info@gaelensbouwprojecten.be</p>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Hidden smUp>
                        <SpaceRow top = "74" />
                        <Grid container item justifyContent = "space-between">
                            <div className = "flex-row">
                                <p className = "main-font-regular font-size-14 text">2021 &nbsp;</p>
                                <p className = "main-font-regular font-size-14 text"> Gaelens Bouwprojecten</p>
                            </div>
                            <img className = "logo" id = "footer" alt = "logo" src = {require('../../../assets/images/logo/logo_simple_black.svg')} />
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        </Grid>
    )
}