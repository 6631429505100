import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import PropTypes from "prop-types";

import { SpaceRow } from "../../../../components/space/Space";

import "./_homepage_two.scss";

function DotLayer(props) {
    const { type, top, left, bottom } = props;

    return(
        <div className = "flex-column absolute" style = {{left, top, bottom}}>
            {type ? (
                <>
                    <div className = "flex-row">
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                    </div>
                    <div className = "flex-row">
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                    </div>
                    <div className = "flex-row">
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                    </div>
                    <div className = "flex-row">
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                    </div>
                    <div className = "flex-row">
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                    </div>
                    <div className = "flex-row">
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                    </div>
                </>
            ): (
                <>
                    <div className = "flex-row">
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                    </div>
                    <div className = "flex-row">
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                        <div className = "dot" id = "homepagetwo" />
                    </div>
                    <div className = "flex-row">
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                    </div>
                    <div className = "flex-row">
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                    </div>
                    <div className = "flex-row">
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                    </div>
                    <div className = "flex-row">
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                        <div className = "dot-alt" id = "homepagetwo" />
                    </div>
                </>
            )}
        </div>
    )
}

export default function HomepageTwo() {

    return(
        <Grid container justifyContent = "center">
            <div className = "container" id = "homepagetwo">
                <div className = "background" id = "homepagetwo">
                    <div className = "background-circle-container-right" />
                    <div className = "background-circle-container-left" />
                    <Hidden mdUp>
                        <DotLayer left = "20%" top = "-33px" />
                    </Hidden>
                    <Hidden smDown>
                        <DotLayer left = "35%" top = "-65px" />
                        <DotLayer left = "51%" bottom = "-90px" type />
                    </Hidden>
                </div>
                <div className  = "content-box relative" id = "homepagetwo">
                    <Grid container>
                        <Hidden mdUp>
                            <SpaceRow top = "25" />
                        </Hidden>
                        <Grid container item sm = {12} md = {6} direction = "column">
                            <p className = "main-font-semi-bold font-size-18 font-color-light-black">Een overzicht van onze</p>
                            <p className = "main-font-regular font-size-50 font-color-blue custom-font">Visie</p>
                            <p className = "main-font-regular font-size-16 font-color-light-black margin-top-15">Met doordachte ontwerpen voor woningen, appartementen en kantoren geven we niet alleen een absolute meerwaarde aan toekomstige bewoners of gebruikers, maar ook aan de omgeving. Kwaliteit, comfort en duurzaamheid staan centraal bij de uitvoering van de bouwprojecten.</p>
                            <p className = "main-font-regular font-size-16 font-color-light-black margin-top-15">Zo garandeert we niet alleen een kwaliteitsvolle woon- en werkomgeving, maar zorgen we ook voor een laag energieverbruik en een hoog rendement op lange termijn voor uw vastgoedinvestering.</p>
                            <p className = "main-font-regular font-size-16 font-color-light-black margin-top-15">Bij Gaelens Bouwprojecten staan de klanten ook centraal. Gedurende het volledige bouwproces van uw woning begeleiden wij u van A tot Z. We helpen u graag met uw vragen als met uw keuzes</p>
                        </Grid>
                        <Hidden smDown>
                            <Grid container item sm = {12} md = {6} justifyContent = "center" alignItems = "center">
                                <div className = "image-box" id = "homepagetwo">
                                    <img src = {require('../../../../assets/images/general/gaelens_2.svg')} alt = "image" className = "image-box-content" id = "homepagetwo" />
                                </div>
                            </Grid>
                        </Hidden>
                        <Hidden mdUp>
                            <SpaceRow top = "65" />
                        </Hidden>
                    </Grid>
                    <Hidden smDown>
                        <div className = "card center-content" id = "homepagetwo">
                            <img src = {require('../../../../assets/images/general/gaelens_1.jpg')} alt = "image" className = "card-image-layer" id = "homepagetwo" />
                            <div className = "card-layer" id = "homepagetwo" />
                            <div className = "flex-column relative">
                                <p className = "main-font-semi-bold font-size-18 font-color-white italic">Gaelens Bouwprojecten is een</p>
                                <p className = "main-font-semi-bold font-size-18 font-color-white italic margin-top-5">vastgoedpromotor gespecialiseerd in het</p>
                                <p className = "main-font-semi-bold font-size-18 font-color-white italic margin-top-5">ontwikkelen en uitvoeren van unieke</p>
                                <p className = "main-font-semi-bold font-size-18 font-color-white italic margin-top-5">residentiële en commerciële projecten</p>
                            </div>
                        </div>
                    </Hidden>
                </div>
            </div>
            <Hidden mdUp>
                <Grid container item sm = {12} md = {6} justifyContent = "flex-end" alignItems = "center">
                    <div className = "image-box" id = "homepagetwo">
                        <img src = {require('../../../../assets/images/general/gaelens_2.svg')} alt = "image" className = "image-box-content" id = "homepagetwo" />
                    </div>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <div className = "card center-content" id = "homepagetwo">
                    <img src = {require('../../../../assets/images/general/gaelens_1.jpg')} alt = "image" className = "card-image-layer" id = "homepagetwo" />
                    <div className = "card-layer" id = "homepagetwo" />
                    <div className = "flex-column relative">
                        <p className = "main-font-semi-bold font-size-18 font-color-white italic">Gaelens Bouwprojecten is een</p>
                        <p className = "main-font-semi-bold font-size-18 font-color-white italic margin-top-5">vastgoedpromotor gespecialiseerd in het</p>
                        <p className = "main-font-semi-bold font-size-18 font-color-white italic margin-top-5">ontwikkelen en uitvoeren van unieke</p>
                        <p className = "main-font-semi-bold font-size-18 font-color-white italic margin-top-5">residentiële en commerciële projecten</p>
                    </div>
                </div>
            </Hidden>
        </Grid>
    )
}

DotLayer.propTypes = {
    type: PropTypes.bool,
    top: PropTypes.string,
    left: PropTypes.string,
    bottom: PropTypes.string
}

DotLayer.defaultProps = {
    type: false,
    top: null,
    left: null,
    bottom: null
}