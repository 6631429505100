import React from 'react';
import {
  Switch, Route, Redirect
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import './core.scss';
import '../assets/styles/scss/fonts.css';
import '../assets/styles/scss/globals.css';

import References from './references/References.view';
import ReferencesDetails from './references/ReferencesDetails.view';
import ErrorMessage from '../components/error-message/ErrorMessage';

// page imports here
import Home from './views/home/home'
import Header from './views/header/header'
import Footer from './views/footer/footer'
import Contact from './views/contact/contact'
import Disclaimer from './views/disclaimer/disclaimer';
import Privacypolicy from './views/privacypolicy/privacypolicy'

export default function Core() {
  return (
    <Switch>
      <Route exact path="/">
        <ErrorBoundary FallbackComponent={ErrorMessage}>
          <Header />
          <Home />
          <Footer /> 
        </ErrorBoundary>
      </Route>
      <Route exact path="/contact">
        <Header />
        <Contact/>
        <Footer />
      </Route>
      <Route exact path="/privacy">
        <Header />
        <Privacypolicy/>
        <Footer />
      </Route>
      <Route exact path="/cookies">
        <Header />
        <Privacypolicy/>
        <Footer />
      </Route>
      <Route exact path="/disclaimer">
        <Header />
        <Disclaimer />
        <Footer />
      </Route>
      <Route exact path="/references">
        <Header />
        <References />
        <Footer />
      </Route>
      <Route exact path="/references/:id">
        <Header />
        <ReferencesDetails />
        <Footer />
      </Route>
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}
