import React, { useState, useEffect } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import axios from "axios";

import { SpaceRow } from '../../../components/space/Space';
import { getAllProjects } from '../../../common/api/routes';
import HomepageOne from './components/homepage_one';
import HomepageTwo from './components/homepage_two';
import HomepageThree from './components/homepage_three';

import './_home.scss';

export default function Home() {
  const [ projectData, setProjectData ] = useState([]);
  const [ lastProjectData, setLastProjectData ] = useState([])

  useEffect(() => {
    axios.get(getAllProjects).then(response => {
      const filteredData = response.data.data.filter(project => project.visible_huidige);
      const filteredDataTwo = response.data.data.filter(project => project.visible);
      setProjectData(filteredData)
      if(filteredDataTwo.length > 4) {
        setLastProjectData(filteredDataTwo.slice(filteredDataTwo.length - 4, filteredDataTwo.length))
      }
      else {
        setLastProjectData(filteredDataTwo)
      }
    }).catch(error => console.log(error))
  }, [])

  return (
    <>
    <Hidden mdDown>
      <Grid container>
        <HomepageOne projectData = {projectData} />
        <SpaceRow top="100" />
        <HomepageTwo />
        <Hidden smDown>
          <SpaceRow top="300" />
        </Hidden>
        <Hidden mdUp>
          <SpaceRow top="100" />
        </Hidden>
        <HomepageThree lastProjectData = {lastProjectData} />
        <SpaceRow top="100" />
      </Grid>
    </Hidden>
    <Hidden lgUp>
      <Grid container style = {{overflow: 'hidden'}}>
        <HomepageOne projectData = {projectData} />
        <SpaceRow top="100" />
        <HomepageTwo />
        <Hidden smDown>
          <SpaceRow top="300" />
        </Hidden>
        <Hidden mdUp>
          <SpaceRow top="100" />
        </Hidden>
        <HomepageThree lastProjectData = {lastProjectData} />
        <SpaceRow top="100" />
      </Grid>
    </Hidden>
    </>
  );
}
