import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Hidden } from '@material-ui/core';
import { useLocation } from "react-router-dom";

import './_header.scss';
import { SpaceRow } from '../../../components/space/Space';

export default function Header() {
  const [ headerState, setHeaderState ] = useState(0)
  const [ menuState, setMenuState ] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if(location.pathname.includes('references')) {
      setHeaderState(1)
    }
    else if(location.pathname.includes('contact')) {
      setHeaderState(2)
    }
    else {
      setHeaderState(0)
    }
  }, [location.pathname])

  return (
    <Grid container>
      <div className="container" id="header">
        <Hidden smDown>
          <div className="flex-row">
            <img className="logo" id="header" alt="logo" src={require('../../../assets/images/logo/logo.png')} />
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className = "header-menu">
            <img className="logo margin-left-10" id="header" alt="logo" src={require('../../../assets/images/logo/logo_simple.svg')} />
            <div className = "menu relative margin-right-10 " id = "header">
              <img src = {require('../../../assets/images/icon/menu.svg')} alt = "menu" className = "menu animatable-400 ease-in-out-quart absolute-origin" id = "header" style = {{opacity: menuState ? 0 : 1, zIndex: menuState ? 1 : 2}} onClick = {() => setMenuState(true)} />
              <img src = {require('../../../assets/images/icon/cross_gray.svg')} alt = "menu" className = "menu animatable-400 ease-in-out-quart absolute-origin" id = "header" style = {{opacity: menuState ? 1 : 0, zIndex: menuState ? 2 : 1}} onClick = {() => setMenuState(false)} />
            </div>
          </div>
          <div className = "header-menu-container animatable-400 ease-in-out-quart" style = {{opacity: menuState ? 1 : 0, height: menuState ? '223px' : '0px'}}>
            <div className = "header-menu-item center-content">
              <Link to = "/">
                <p className="main-font-semi-bold font-size-16 font-color-white cursor-pointer">Onze projecten</p>
              </Link>
            </div>
            <div className = "header-menu-item center-content">
              <Link to = "/references">
                <p className="main-font-semi-bold font-size-16 font-color-white cursor-pointer">Referenties</p>
              </Link>
            </div>
            <div className = "header-menu-item center-content">
              <Link to = "/contact">
                <p className="main-font-semi-bold font-size-16 font-color-white cursor-pointer">Contact</p>
              </Link>
            </div>
            <div className = "header-menu-item center-content">
              <a href="https://klantenportaal.gaelensbouwprojecten.be/" target="_blank" >
              <p className = "main-font-regular font-size-16 font-color-white">Klantenportaal</p>
              </a>
            </div>
            <SpaceRow top = "15" />
          </div>
        </Hidden>
        <Hidden smDown>
          <div className="flex-row vertical-center">
            <div className = "flex-column vertical-center">
              <Link to = "/">
                <p className="main-font-semi-bold font-size-16 font-color-blue-grey cursor-pointer">Onze projecten</p>
              </Link>
              {headerState === 0 && (
                <div className = "underline" id = "header" />
              )}
            </div>
            <div className = "margin-right-50" />
            <div className = "flex-column vertical-center">
              <Link to = "/references">
                <p className="main-font-semi-bold font-size-16 font-color-blue-grey cursor-pointer">Referenties</p>
              </Link>
              {headerState === 1 && (
                <div className = "underline" id = "header" />
              )}
            </div>
            <div className = "margin-right-50" />
            <div className = "flex-column vertical-center">
              <Link to = "/contact">
                <p className="main-font-semi-bold font-size-16 font-color-blue-grey cursor-pointer">Contact</p>
              </Link>
              {headerState === 2 && (
                <div className = "underline" id = "header" />
              )}
            </div>
            <div className = "margin-right-50" />
            <button className="button center-content" id="header">
            <a href="https://klantenportaal.gaelensbouwprojecten.be/" target="_blank" >
              <p className="main-font-semi-bold font-size-16 font-color-blue">Klantenportaal</p>
              </a>
            </button>
          </div>
        </Hidden>
      </div>
    </Grid>
  );
}
