import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Carousel from 'react-slider-responsive';

import FullImageModal from './FullImageModal';
import WindowDimensions from '../WindowDimensions';
import { assetsUrl } from '../../../common/api/routes';

const ReferencesPages = ({
  allImages,
}) => {
  const [showImage, toggleShowImage] = useState('');
  const { width } = WindowDimensions();

  const openImage = (image) => {
    toggleShowImage(image);
  };

  const closeImage = () => {
    toggleShowImage('');
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <div style={{ float: 'right' }} className = "carousel-container">
          {width > 960 ? (
            <Grid container>
              <Grid item md={4} className="image-1-resp">
                <img
                  alt="Project Image"
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    maxHeight: '80%',
                    height: '100%',
                  }}
                  src={`${assetsUrl}${allImages[0]?.image}` || require('../../../assets/images/placeholder/no-photo.png')}
                  onClick={() => openImage(`${assetsUrl}${allImages[0]?.image}`)}
                />
              </Grid>
              <Grid item md={8} className="image-1-resp">
                <img
                  alt="Project Image"
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    maxHeight: '80%',
                    height: '100%',
                  }}
                  src={`${assetsUrl}${allImages[1]?.image}` || require('../../../assets/images/placeholder/no-photo.png')}
                  onClick={() => openImage(`${assetsUrl}${allImages[1]?.image}`)}
                />
              </Grid>
            </Grid>
          ) : (
            <Carousel>
              <div>
                <img
                  src={`${assetsUrl}${allImages[0]?.image}` || require('../../../assets/images/placeholder/no-photo.png')}
                  alt="Project Image"
                />
              </div>
              <div>
                <img
                  src={`${assetsUrl}${allImages[1]?.image}` || require('../../../assets/images/placeholder/no-photo.png')}
                  alt="Project Image"
                />
              </div>
              <div>
                <img
                  src={`${assetsUrl}${allImages[2]?.image}` || require('../../../assets/images/placeholder/no-photo.png')}
                  alt="test-slide"
                />
              </div>
            </Carousel>
          )}
        </div>
      </Grid>

      {showImage && (
        <FullImageModal
          imagePath={showImage}
          handleClose={closeImage}
          isOpen={!isEmpty(showImage)}
        />
      )}

    </Grid>
  );
};

ReferencesPages.styles = {};

ReferencesPages.propTypes = {
  allImages: PropTypes.array.isRequired,
};

export default ReferencesPages;
