import React, { useState, useEffect } from "react";
import { Grid, Hidden, } from "@material-ui/core"
import "./_contact.scss";
import { SpaceRow } from "../../../components/space/Space";
import axios from "axios";
import { getAllProjects, sendContactApi } from "../../../common/api/routes";
import { Dialog } from "@material-ui/core";

function RadioButton(props) {
    const { isActive, onClick } = props;

    return (
        <div className="contact-radio-button-container center-content cursor-pointer" style={{ border: isActive ? 'none' : 'solid 1px RGB(170, 170, 170)' }} onClick={onClick}>
            <div className="contact-radio-button-blue center-content animatable-400 ease-in-out-quart" style={{ opacity: isActive ? 1 : 0 }}>
                <div className="contact-radio-button-white" />
            </div>
        </div>
    )
}

function Switch(props) {
    const { switchState, onClick } = props;

    const handleOnClick = () => {
        onClick();
    }

    return(
        <div className = "contact-switch-container vertical-center animatable-400 ease-in-out-quart cursor-pointer" style = {{backgroundColor: switchState ? '#2EA0B4' : '#979797'}} onClick = {handleOnClick}>
            <div className = "contact-switch animatable-400 ease-in-out-quart" style = {{left: switchState ? 'calc(100% - 19px)' : '1px'}} />
        </div>
    )
}

export default function Contact() {
    const [ naam, setNaam ] = useState("");
    const [ voornaam, setVoornaam ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ telefoon, setTelefoon ] = useState("");
    const [ bericht, setBericht ] = useState("");
    const [ naamError, setNaamError ] = useState(false);
    const [ voornaamError, setVoornaamError ] = useState(false);
    const [ berichtError, setBerichtError ] = useState(false)
    const [ emailError, setEmailError ] = useState(false);
    const [ radioButtonState, setRadioButtonState ] = useState(0)
    const [ activeInput, setActiveInput ] = useState(0)
    const [ switchState, setSwitchState ] = useState(false)
    const [ projectData, setProjectData ] = useState([]);
    const [ modalState, setModalState ] = useState(false)
    const [ isMessageMandatory, setIsMessageMandatory ] = useState(true)

    useEffect(() => {
        axios.get(getAllProjects).then(response => {
        const filteredData = response.data.data.filter(project => project.visible_huidige);
        setProjectData(filteredData)
        }).catch(error => console.log(error))
    }, [])

    const onFormSubmit = () => {
        let apiData = {
            firstName: voornaam,
            lastName: naam,
            email: email,
            mobile: telefoon,
            message: bericht

        };
        if(switchState) {
            if((radioButtonState + 1) <= projectData.length) {
                apiData.project_selected = true;
                apiData.project_name = projectData[radioButtonState].name;
                apiData.project_id = projectData[radioButtonState].id;
                apiData.project_city = projectData[radioButtonState].city;
            }
            else {
                // andere option
                apiData.project_selected = true;
                apiData.project_name = 'Andere';
                apiData.project_city = 'Andere';
            }
        }
        else{
            apiData.project_selected = false;
        }

        if(validateForm()) {
            axios.post(sendContactApi, apiData).then(response => {
                setNaam("")
                setVoornaam("")
                setEmail("")
                setTelefoon("")
                setBericht("")
                setSwitchState(false)
                setRadioButtonState(0)
                setModalState(true)
            }).catch(error => console.log(error))
        }
    }

    const handleNaamChange = event => {
        setNaam(event.target.value)
        setNaamError(false)
    }

    const handleVoornaamChange = event => {
        setVoornaam(event.target.value)
        setVoornaamError(false)
    }

    const handleEmailChange = event => {
        setEmail(event.target.value)
        if(event.target.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setEmailError(false)
        }
        else {
            setEmailError(true)
        }
    }

    const handleBerichtChange = event => {
        setBericht(event.target.value)
        setBerichtError(false)
    }

    const handleSwitchChange = () => {
        setSwitchState(!switchState);
        setIsMessageMandatory(!isMessageMandatory);
        setBerichtError(false)
    }

    const validateForm = () => {
        let isValid = true;
        if(naam === "") {
            isValid = false;
            setNaamError(true)
        }
        if(voornaam === "") {
            isValid = false;
            setVoornaamError(true)
        }
        if(email === "" || !email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            isValid = false;
            setEmailError(true)
        }
        if(isMessageMandatory) {
            if(bericht === "") {
                isValid = false;
                setBerichtError(true)
            }
        }
        return isValid;
    }

    const calcMaxHeight = () => {
        if(window.screen.width < 600) {
            // this is mobile view
            return 65 * (projectData.length + 1);
        }
        else {
            const totalData = projectData.length + 1;
            let rows = totalData / 4;
            rows = parseInt(rows);
            if(totalData % 4 !== 0) {
                rows += 1;
            }
            return 65  * rows;
        }
    }

    return (

            <Grid container className="main" id="contact">
                <Hidden smUp>
                    <SpaceRow top = "15" />
                </Hidden>
                <Grid container xs={12} md={3} className="sub relative" id="contact">
                    <div className="bubble" id="contact" />
                    <div className="contact-circle-container center-content">
                        <div className="contact-circle" />
                    </div>
                    <div className="contact-oval-container flex-column">
                        <div className="flex-row">
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                        </div>
                        <div className="flex-row">
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                        </div>
                        <div className="flex-row">
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                            <div className="contact-oval" />
                        </div>
                    </div>
                    <div className="contacteer">
                    <p className="para padding-top-40 padding-left-30 padding-bottom-13" id="contact">Contacteer ons</p>
                    <div className="line margin-left-30 margin-bottom-20"id="contact"></div>
                    <p className=" paranew font-color-white" id="contact">Gelieve uw gegevens hiernaast in te vullen zodat wij contact met u kunnen opnemen. Indien u interesse toont in één project gelieve dit dan aan te vinken.</p>
                    <div className="flex-row">
                        <img className="logo padding-left-30" alt="logo" id="contact" src={require('../../../assets/images/icon/phone2.svg')} />
                        <p className="para1 font-color-white padding-bottom-20 font-weight-semibold  padding-left-30  font-size-14 "id="contact"> +32 9 245 03 33</p>
                    </div>
                    <div className="flex-row">
                        <img className="logo padding-left-30" alt="logo" id="contact" src={require('../../../assets/images/icon/phone1.svg')} />
                        <p className="padding-bottom-15  para1 font-color-white padding-left-30" id="contact">info@gaelensbouwprojecten.be </p>

                    </div>
                    <div className="flex-row">
                        <img className="logo padding-left-30" alt="logo" id="contact" src={require('../../../assets/images/icon/newbooth.svg')} />
                        <p className="para1 font-color-white padding-left-30 " id="contact">Kortrijksesteenweg 1008/001,<br></br>9000 Gent</p>
                    </div>
                    </div>
                </Grid>
                <Grid container xs={12} md={8} className="form1" id="contact">
                    <Grid  container  className="formm" id="contact" >
                        <Grid container>
                            <Grid container xs = {12} sm = {6}>
                                <Grid container item xs = {12} sm = {11} className="textform relative" direction = "column" id="contact">
                                  <p className="text animatable-400 ease-in-out-quart" id="contact" style = {{color: voornaamError ? 'red' : activeInput === 2 ? '#2EA0B4' : '#45545E'}}>Voornaam *</p>
                                  <SpaceRow bottom = "5" />
                                  <div className = "contact-text-border-default animatable-400 ease-in-out-quart" />
                                  <div className = "contact-text-border animatable-400 ease-in-out-quart" style = {{width: voornaamError ? '100%' : activeInput === 2 ? '100%' : '0%', borderColor: voornaamError ? 'red' : '#2EA0B4'}} />
                                  <input className="border-bottom-2px solid black relative" type="text" placeholder="Voornaam" value = {voornaam} onChange={handleVoornaamChange} onFocus = {() => setActiveInput(2)} onBlur = {() => setActiveInput(0)} />
                                  <SpaceRow top = "5" />
                                </Grid>
                                <Grid container xs = {12} sm = {1} />
                            </Grid>
                            <Grid container xs={12} sm={6}>
                                <Grid container xs = {12} sm = {1} />
                                <Grid container item xs = {12} sm = {11} className="textform2 relative" id="contact" direction = "column">
                                  <p className="text animatable-400 ease-in-out-quart" id="contact" style = {{color: naamError ? 'red' : activeInput === 1 ? '#2EA0B4' : '#45545E'}}>Naam *</p>
                                  <SpaceRow bottom = "5" />
                                  <div className = "contact-text-border-default animatable-400 ease-in-out-quart" />
                                  <div className = "contact-text-border animatable-400 ease-in-out-quart" style = {{width: naamError ? '100%' : activeInput === 1 ? '100%' : '0%', borderColor: naamError ? 'red' : '#2EA0B4'}} />
                                  <input className="border-bottom-2px solid black relative" type="text" placeholder="Naam" value = {naam} onChange={handleNaamChange} onFocus = {() => setActiveInput(1)} onBlur = {() => setActiveInput(0)} />
                                  <SpaceRow top = "5" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={12} sm={6} >
                                <Grid container xs = {12} sm = {11} className="textform relative" id="contact" direction = "column">
                                    <p className="text animatable-400 ease-in-out-quart" id="contact" style = {{color: emailError ? 'red' : activeInput === 3 ? '#2EA0B4' : '#45545E'}}>Email *</p>
                                    <SpaceRow bottom = "5" />
                                    <div className = "contact-text-border-default animatable-400 ease-in-out-quart" />
                                    <div className = "contact-text-border animatable-400 ease-in-out-quart" style = {{width: emailError ? '100%' : activeInput === 3 ? '100%' : '0%', borderColor: emailError ? 'red' : '#2EA0B4'}} />
                                    <input className="border-bottom-2px solid black relative" type="text" placeholder="Email" value = {email} onChange={handleEmailChange} onFocus = {() => setActiveInput(3)} onBlur = {() => setActiveInput(0)} />
                                    <SpaceRow top = "5" />
                                </Grid>
                                <Grid container xs = {12} sm = {1} />
                            </Grid>
                            <Grid container xs={12} sm={6}>
                                <Grid container xs = {12} sm = {1} />
                                <Grid container xs = {12} sm = {11} className="textform2 relative" id="contact" direction = "column">
                                    <p className="text animatable-400 ease-in-out-quart" id="contact" style = {{color: activeInput === 4 ? '#2EA0B4' : '#45545E'}}>Telefoon</p>
                                    <SpaceRow bottom = "5" />
                                    <div className = "contact-text-border-default animatable-400 ease-in-out-quart" />
                                    <div className = "contact-text-border animatable-400 ease-in-out-quart" style = {{width: activeInput === 4 ? '100%' : '0%',}} />
                                    <input className="border-bottom-2px solid black relative" type="text" placeholder="Telefoonnummer" value = {telefoon} onChange={event => setTelefoon(event.target.value)} onFocus = {() => setActiveInput(4)} onBlur = {() => setActiveInput(0)} />
                                    <SpaceRow top = "5" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} sm={12} className="textform relative" id="contact" item>
                            <p className="text animatable-400 ease-in-out-quart" id="contact" style = {{color: berichtError ? 'red' : activeInput === 5 ? '#2EA0B4' : '#45545E'}}>Bericht {isMessageMandatory && '*'}</p>
                            <SpaceRow bottom = "5" />
                            <div className = "contact-text-border-default animatable-400 ease-in-out-quart" />
                            <div className = "contact-text-border animatable-400 ease-in-out-quart" style = {{width: berichtError ? '100%' : activeInput === 5 ? '100%' : '0%', borderColor: berichtError ? 'red' : '#2EA0B4'}} />
                            <input className="border-bottom-2px solid black relative width-100-p" type="text" placeholder="Schrijf uw bericht in" value = {bericht} onChange={handleBerichtChange} onFocus = {() => setActiveInput(5)} onBlur = {() => setActiveInput(0)} />
                            <SpaceRow top = "5" />
                        </Grid>
                        <Grid container xs={12} md={12} className="margin-top-20" alignItems = "center">
                            <Switch switchState = {switchState} onClick = {handleSwitchChange} />
                            <p className="main-font-semi-bold font-size-14 font-color-black margin-left-10">Ik heb interesse voor een project</p>
                        </Grid>
                        <Grid container xs={12} md={12} className="margin-top-30 animatable-400 ease-in-out-quart overflow-hidden" style = {{opacity: switchState ? 1 : 0, transform: switchState ? 'translateY(0px)' : 'translateY(-15px)', maxHeight: switchState ? `${calcMaxHeight()}px` : '0px'}}>
                            {projectData && projectData.length > 0 && projectData.map((project, projectIndex) => (
                                <Grid container item xs={12} md={3} className="">
                                    <RadioButton isActive={radioButtonState === (projectIndex) ? true : false} onClick={() => setRadioButtonState(projectIndex)} />
                                    <div className=" margin-bottom-20 margin-left-10">
                                        <p className="paratext1" id="contact">{project?.name || ""}</p>
                                        <p className="paratext1 margin-top-3" id="contact">{project?.city || ""}</p>
                                    </div>
                                </Grid>
                            ))}
                            <Grid container xs={12} md={3} >
                                <RadioButton isActive={radioButtonState === projectData.length ? true : false} onClick={() => setRadioButtonState(projectData.length)} />
                                <div className="flex-column margin-left-10">
                                    <p className="paratext1" id="contact">Andere</p>
                                    <p className="paratext1 margin-top-3" id="contact">Andere</p>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container item xs = {12} sm = {12} >
                        <Grid  item xs = {12} sm = {12}>
                          <p className="margin-left-5 margin-top-30 font-size-14">Bij het versturen van uw email worden de gegevens bewaard</p>
                        </Grid>
                            <button className=" button border-radius-5 background-color-green cursor-pointer" type="button" id="contact" onClick = {onFormSubmit}>
                                Zenden
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
                <SpaceRow top = "50" />
                <Dialog open = {modalState} onClose = {() => setModalState(false)} onBackdropClick = {() => setModalState(false)}>
                    <div className = "height-100-p width-100-p center-content">
                        <div className = "modal-box flex-column vertical-center" id = "contact">
                            <p className = "text" id = "contact">E-mail succesvol verzonden</p>
                            <button className=" button-alt border-radius-5 background-color-green cursor-pointer" type="button" id="contact" onClick = {() => setModalState(false)}>
                                Okay
                            </button>
                        </div>
                    </div>
                </Dialog>
            </Grid>

    )
}
