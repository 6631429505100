import { Grid } from "@material-ui/core";
import React, { useLayoutEffect } from "react";
import { SpaceRow } from "../../../components/space/Space";
import { useHistory } from "react-router-dom";
import "./disclaimer.scss";

export default function Disclaimer() {
    const history = useHistory();

    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    return (
        <Grid container>
            <Grid  className="navstyle relative" id="disclaimer" direction="column" justifyContent = "center" container  xs={12} md={12}>
                <div className = "background-container" id = "homepageone">
                    <div className = "background-alt" id = "homepageone" />
                </div>
                <div className = "button center-content relative" id = "disclaimer" onClick = {() => history.go(-1)}>
                    <img src = {require('../../../assets/images/icon/arrow_previous_blue.svg')} alt = "back" className = "button-icon" id = "disclaimer" />
                </div>
                <p  className="navheading relative" id="disclaimer">
                    Disclaimer
                </p>
                <p  className="navpara relative" id="disclaimer">
                    Voorwaarden voor gebruik
                </p>
                <p  className="navpara1 relative" id="disclaimer">
                    Het bezoeken, doorbladeren en bekijken van deze website valt onder onze gebruiksvoorwaarden en onder alle toepasselijke wetten. Door deze website te bezoeken, te doorbladeren en te bekijken, aanvaardt u de gebruiksvoorwaarden, zoals hieronder beschreven, zonder beperking of uitsluiting.
                </p>
            </Grid>
            <Grid container alignItems = "flex-start">
                <Grid container className="newstyle" id="disclaimer" xs={12} md={6}>
                    <p className="heading" id="disclaimer">
                        Auteursrecht
                    </p>
                    <p className="para" id="disclaimer">
                    De inhoud van deze website is auteursrechtelijk beschermd. De auteursrechten berusten bij Gaelens Bouwprojecten, haar klanten en haar partners. Alle rechten zijn voorbehouden. Informatie van de website (waaronder maar niet uitsluitend tekst, presentaties, illustraties, foto's en geluid) mag, tenzij anders is aangegeven, niet worden gekopieerd, overgeplaatst, verspreid of opgeslagen zonder schrielijke toestemming vooraf van Gaelens Bouwprojecten. Wijzigingen op de inhoud van de site zijn verboden. Delen van de site bevatten illustraties die uitdrukkelijk onder het auteursrecht van de klanten vallen.
                    </p>
                    
                </Grid>
                <Grid container className="newstyle" id="disclaimer" xs={12} md={6}>
                    <p className="heading " id="disclaimer">
                        Aansprakelijkheid
                    </p>
                    <p className="para" id="disclaimer">
                        Aan de informatie, die op deze website getoond wordt, kunnen op geen enkele wijze rechten of aanspraken ontleend worden. Links naar andere websites worden als service aangeboden en het betekent niet dat Gaelens Bouwprojecten verantwoordelijk is voor de inhoud van deze websites. Bovendien is het uw eigen verantwoordelijkheid te controleren of deze websites vrij zijn van virussen en andere zaken met een schadelijk karakter. Informatie op onze website kan door ons worden gewijzigd zonder voorafgaande aankondiging of verplichting.
                    </p>
                </Grid>
            </Grid>
            <Grid container alignItems = "flex-start">
                <Grid container className="newstyle" id="disclaimer" xs={12} md={6}>
                    <p className="heading" id="disclaimer">
                        Opmerkingen, vragen en suggesties
                    </p>
                    <p className="para" id="disclaimer">
                        Gaelens Bouwprojecten wenst geen vertrouwelijke of voorbehouden informatie te ontvangen via deze website. Alle door u aan ons verstrekte informatie (suggesties, ideeën en overige) zal worden beschouwd als niet-vertrouwelijk en openbaar (uitgezonderd zoals beschreven in punt 1. met betrekking tot de bescherming van de persoonlijke levenssfeer). Door Gaelens Bouwprojecten materiaal te sturen, gee u aan Gaelens Bouwprojecten het onbeperkte en onherroepbare recht deze informatie te gebruiken, te vertonen, te wijzigen, te verwerken en te verspreiden en deze informatie rechtelijk te registreren. Tevens kan Gaelens Bouwprojecten de ideeën en technieken vrij toepassen die u ons laat toekomen. Gaelens Bouwprojecten hee deze website met de grootst mogelijke zorg samengesteld. Gaelens Bouwprojecten houdt zich aanbevolen voor suggesties of commentaar. Indien bezwaar bestaat tegen bepaalde teksten of afbeeldingen, kan dat ook kenbaar worden gemaakt. 
                    </p>
                </Grid>
                <Grid container className="newstyle" id="disclaimer" xs={12} md={6}>
                    <p className="heading" id="disclaimer">
                        Rechtspraak 
                    </p>
                    <p className="para" id="disclaimer">
                        Het staat Gaelens Bouwprojecten vrij op elk moment de gebruiksvoorwaarden te wijzigen door die opnieuw op deze website te publiceren. De gebruiksvoorwaarden zijn conform de Belgische rechtspraak en de geschillen zullen beslecht worden conform diezelfde rechtspraak. In het geval dat een onderdeel van deze gebruiksvoorwaarden door een rechtbank met toepasselijke jurisdictie ongeldig of onuitvoerbaar zal worden verklaard, zal het betreende deel van de gebruiksvoorwaarden als vervallen beschouwd worden, terwijl de resterende bepalingen van deze gebruiksvoorwaarden in volle geldigheid en werking van kracht zullen blijven. 
                    </p>
                </Grid>  
            </Grid>
            <SpaceRow top = "50" />
        </Grid>
    )
}