import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { assetsUrl } from '../../common/api/routes';

const ItemCard = ({
  project,
  heading,
  subHeading,
  onClick
}) => {
  const [hoverState, setHoverState] = useState(false);
  const history = useHistory();

  const openProject = () => {
    if (project?.projectDetail?.link) {
      window.open(project?.projectDetail?.linkDetail);
    } else {
      history.push(`/references/${project?.id}`);
    }
  };

  function getImage(imageData, type) {
    // if type is true, then it fetches the non-reference image, if it is false, then it fetches the reference image first
    const primaryImage = imageData.find((image) => (type ? !image.reference : image.reference));
    if (primaryImage) {
      return primaryImage.image;
    }

    const secondaryImage = imageData.find((image) => (type ? image.reference : !image.reference));
    if (secondaryImage) {
      return secondaryImage.image;
    }

    return '';
  }

  const card = (
    <>
      <img
        alt="test"
        style={{
          objectFit: 'cover',
          height: '100%',
          width: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
        src={`${assetsUrl}${getImage(project?.projectImages)}`}
      />
      <div className="flex-column relative">
        <p className="main-font-regular font-size-28 font-color-white" style={{ fontWeight: 700 }}>
          {heading}
        </p>
        <div className="card-bar margin-top-10" id="homepagethree" />
        <p className="main-font-regular font-size-18 font-color-white margin-top-10" style={{ fontWeight: 700 }}>
          {subHeading}
        </p>
      </div>
      {hoverState && (
        <button type="button" className="button center-content" id="homepagethree" onClick={openProject}>
          <img src={require('../../assets/images/icon/arrow_next_blue.svg')} alt="arrow next" className="button-icon" id="homepagethree" />
        </button>
      )}
    </>
  );

  return (
    <div
      id="homepagethree"
      className="card-container relative cursor-pointer"
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      style={{ width: '100%', height: 475 }}
      onClick = {openProject}
    >
      {card}
    </div>
  );
};

ItemCard.defaultProps = {
  onClick: () => {}
};

ItemCard.propTypes = {
  project: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default ItemCard;
