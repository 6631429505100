import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import FullImageModal from './FullImageModal';
import { assetsUrl } from '../../../common/api/routes';

const ReferencesPages = ({
  allImages,
}) => {
  const [showImage, toggleShowImage] = useState('');

  const openImage = (image) => {
    toggleShowImage(image);
  };

  const closeImage = () => {
    toggleShowImage('');
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <div className="image-1-resp">
          <img
            alt={require('../../../assets/images/placeholder/image_one.jpg')}
            onClick={() => openImage(`${assetsUrl}${allImages[0]?.image}`)}
            style={{
              objectFit: 'cover',
              width: '100%',
              maxHeight: '80%',
              height: '100%',
            }}
            src={!isEmpty(allImages)
              ? `${assetsUrl}${allImages[0]?.image}`
              : require('../../../assets/images/placeholder/no-photo.png')}
          />
        </div>
      </Grid>

      {showImage && (
        <FullImageModal
          imagePath={showImage}
          handleClose={closeImage}
          isOpen={!isEmpty(showImage)}
        />
      )}

    </Grid>
  );
};

ReferencesPages.styles = {};

ReferencesPages.propTypes = {
  allImages: PropTypes.array.isRequired,
};

export default ReferencesPages;
