import request from '../../common/request';

export const getAllReferencesProjects = (setFetching, setResponse) => {
  if (setFetching) {
    setFetching(true);
  }

  const url = '/web/project?visible=true';
  const response = request.get(url);

  response
    .then((res) => {
      if (setFetching) {
        setFetching(false);
      }
      setResponse(res.data.data);
    })
    .catch(() => {
      if (setFetching) {
        setFetching(false);
      }
    });
};

export const getReferencesDetails = (setFetching, setResponse, referenceId) => {
  if (setFetching) {
    setFetching(true);
  }

  const url = `/web/project/show/${referenceId}`;
  const response = request.get(url);

  response
    .then((res) => {
      if (setFetching) {
        setFetching(false);
      }
      setResponse(res.data.data);
    })
    .catch(() => {
      if (setFetching) {
        setFetching(false);
      }
    });
};
