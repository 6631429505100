import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import Spinner from '../../components/spinner/Spinner';
import ItemCard from '../../components/item-card/ItemCard.view';

import { getAllReferencesProjects } from './references.api';

const ReferencesPages = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    getAllReferencesProjects(setIsFetching, setProjects);
  }, []);

  const { topHeading } = ReferencesPages.styles;

  return (
    <>
      <div
        className="margin-bottom-40 margin-top-40-custom references-page-top-side-margin"
        style={{
          background: '#f2f3f4',
          width: '100%',
        }}
      >
        <p className="font-size-18" style={{ fontWeight: 700 }}>Een Overicht van onze laatste</p>
        <p className="font-size-24 margin-bottom-20" style={topHeading}>Realisaties</p>
      </div>
      <Grid container className="container margin-top-42 margin-bottom-42 references-page-side-margin" id="homepageone">
        {isFetching ? (
          <div className="text-center padding-top-20">
            <Spinner text="Fetching Projects..." />
          </div>
        ) : (
          <Grid container justifyContent="center">
            {projects?.map((card) => {
              return (
                <Grid
                  item
                  md={4}
                  container
                >
                  <div
                    style={{
                      width: '100%',
                      borderRadius: 10,
                      marginBottom: 15,
                      border: '1px solid #e1e1e1',
                      backgroundImage: 'linear-gradient(to top, rgb(229 229 229 / 10%), #f7f7f7)',
                    }}
                    className="ref-page-main-margin-right"
                  >
                    <ItemCard
                      subHeading={card?.city}
                      heading={card?.name}
                      project={card}
                    />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    </>
  );
};

ReferencesPages.styles = {
  topHeading: {
    fontFamily: 'Amithen',
    color: '#2fa0b4',
    paddingTop: 5,
    fontSize: 50,
  },
};

export default ReferencesPages;
