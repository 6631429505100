import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import './space.scss';

export function SpaceRow(props) {
  const {
    top,
    bottom,
  } = props;

  return (
    bottom
      ? (
        <Grid container>
          <div className={`margin-bottom-${bottom}`} />
        </Grid>
      )
      : (
        <Grid container>
          <div className={`margin-top-${top}`} />
        </Grid>
      )
  );
}

SpaceRow.defaultProps = {
  top: '1',
  bottom: undefined,
};

SpaceRow.propTypes = {
  top: PropTypes.string,
  bottom: PropTypes.string,
};
