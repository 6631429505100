import React, { useEffect, useState, useRef } from "react";
import { Grid, Hidden } from "@material-ui/core";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";

import { SpaceRow } from "../../../../components/space/Space";

import "./_homepage_one.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { assetsUrl } from '../../../../common/api/routes'

export default function HomepageOne(props) {

    const [ selectedProject, setSelectedProject ] = useState(0)
    const [ bubbleCount, setBubbleCount ] = useState(0)
    const [ selectedSection, setSelectedSection ] = useState(0)

    const { projectData } = props;
    const history = useHistory()
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: window.screen.width < 1200 ? 2 : 6,
        slidesToScroll: window.screen.width < 1200 ? 2 : 6
    }
    const sliderRef = useRef(0)

    useEffect(() => {
        if(window.screen.width < 1200) {
            if(projectData.length > 0) {
                let count = projectData.length / 2;
                count = Math.round(count);
                setBubbleCount(count)
            }
            else {
                setBubbleCount(0)
            }
        }
        else {
            if(projectData.length > 0) {
                let remainder = projectData.length % 6;
                let count = projectData.length / 6;
                count = parseInt(count);
                if(remainder === 0) {
                    setBubbleCount(count)
                }
                else {
                    setBubbleCount(count + 1)
                }
            }
            else {
                setBubbleCount(0)
            }
        }
    }, [projectData])

    const handleNextProject = () => {
        if(selectedProject !== projectData.length - 1) {
            setSelectedProject(selectedProject + 1)
            sliderRef.current.slickGoTo(selectedProject + 1)
        }
    }

    const handlePreviousProject = () => {
        if(selectedProject !== 0) {
            setSelectedProject(selectedProject - 1);
            sliderRef.current.slickGoTo(selectedProject - 1)
        }
    }

    const onSlideChange = index => {
        let modifIndex = Math.round(index /(window.screen.width < 1200 ? 2 : 6));
        console.log(Math.round(index/6))
        if(modifIndex > selectedSection) {
            setSelectedSection(selectedSection + 1);
        }
        else if(modifIndex < selectedSection) {
            setSelectedSection(selectedSection - 1)
        }
    }

    const openProject = () => {
        if(projectData[selectedProject]?.project_detail?.link) {
            window.open(projectData[selectedProject]?.project_detail?.link_detail)
        }
        else {
            history.push("/references/" + projectData[selectedProject]?.id)
        }
    }

    function getImage(imageData, type) {
        // if type is true, then it fetches the non-reference image, if it is false, then it fetches the reference image first
        const primaryImage = imageData.find(image => (type ? !image.reference : image.reference));
        if(primaryImage) {
            return primaryImage.image;
        }
        else {
            const secondaryImage = imageData.find(image => (type ? image.reference : !image.reference));
            if(secondaryImage) {
                return secondaryImage.image;
            }
            else {
                return "";
            }
        }
    }

    return(
        <Grid container className = "homepage-mobile-padding">
            <div className = "width-auto margin-top-42 margin-bottom-42">
                <Grid container className = "relative">
                    <Hidden smDown>
                        <div className = "background-container" id = "homepageone">
                            <div className = "background" id = "homepageone" />
                        </div>
                    </Hidden>
                    <Grid container className = "relative container" id = "homepageone">
                        <Grid container>
                            <Grid container>
                                <Grid container className = "relative">
                                    <div className = "width-auto">
                                        <Slider {...settings} afterChange = {onSlideChange} ref = {sliderRef}>
                                            {projectData && projectData.length > 0 && projectData.map((project, projectIndex) => (
                                                    <div>
                                                    <div className = "card animatable-400 ease-in-out-quart flex-shrink-disable" id = "homepageone" onClick = {() => setSelectedProject(projectIndex)} style = {{backgroundColor: selectedProject === projectIndex ? 'RGB(46, 160, 180)' : 'RGB(241, 243, 243)'}}>
                                                        <p className = "main-font-semi-bold font-size-14 font-color-light-black animatable-400 ease-in-out-quart" style = {{color: selectedProject === projectIndex ? 'white' : 'rgb(34, 34, 34)'}}>{project?.name || ""}</p>
                                                        <p className = "main-font-semi-bold font-size-14 font-color-light-black animatable-400 ease-in-out-quart" style = {{color: selectedProject === projectIndex ? 'white' : 'rgb(34, 34, 34)'}}>{project?.city || ""}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </Grid>
                                <SpaceRow top = "10" />
                                <Grid container justifyContent = "center">
                                    {bubbleCount > 1 && (
                                        <>
                                            {Array(bubbleCount).fill().map((_, bubbleIndex) => (
                                                <div className = "slider-bubble animatable-400 ease-in-out-quart" id = "homepageone" style = {{backgroundColor: bubbleIndex === selectedSection ? '#2EA0B4' : 'white', border: bubbleIndex === selectedSection ? 'none' : 'solid 1px #4a4a4a'}} />
                                            ))}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <SpaceRow top = "26" />
                        <Grid container className = "relative">
                           <Hidden smDown>
                                <Grid container item sm = {12} md = {5} alignItems = "center" justifyContent = "center">
                                    <div className = "flex-column vertical-end">
                                        <p className = "main-font-bold font-size-40 font-color-black">{projectData[selectedProject]?.name}</p>
                                        <div className = "bar margin-top-15" id = "homepageone" />
                                        <p className = "main-font-semi-bold font-size-28 font-color-black margin-top-15">{projectData[selectedProject]?.city}</p>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid container item sm = {12} md = {7} justifyContent = "flex-end">
                                <div className = "image relative horizontal-center" id = "homepageone">
                                    {projectData[selectedProject]?.project_images && projectData[selectedProject]?.project_images.length > 0 ? (
                                        <img src = {`${assetsUrl}${getImage(projectData[selectedProject]?.project_images, true)}`} alt = "main" className = "height-auto width-auto image-main" id = "homepageone" />
                                    ) : (
                                        <img src = {require('../../../../assets/images/placeholder/no-photo.png')} alt = "main" className = "height-auto width-auto image-main" id = "homepageone" />
                                    )}
                                    <Hidden mdUp>
                                        <div className = "background-text-container" id = "homepageone">
                                            <p className = "main-font-bold font-size-40 font-color-white">{projectData[selectedProject]?.name}</p>
                                            <div className = "bar margin-top-15" id = "homepageone" />
                                            <p className = "main-font-semi-bold font-size-28 font-color-white margin-top-15">{projectData[selectedProject]?.city}</p>
                                        </div>
                                    </Hidden>
                                </div>
                            </Grid>
                            <Hidden smDown>
                                <button type = "button" className = "arrow-left center-content" id = "homepageone" onClick = {handlePreviousProject}>
                                    <img src = {require('../../../../assets/images/icon/arrow_previous_blue.svg')} alt = "previous" className = "arrow-icon" />
                                </button>
                                <button type = "button" className = "arrow-right center-content" id = "homepageone" onClick = {handleNextProject}>
                                    <img src = {require('../../../../assets/images/icon/arrow_next_blue.svg')} alt = "previous" className = "arrow-icon" />
                                </button>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className = "container" id = "homepageone">
                    <SpaceRow top = "100" />
                    <Grid container item sm = {12} md = {7}>
                        <Grid container item sm = {12} md = {12} direction = "column">
                            <Grid container item sm = {12} md = {11} lg = {12}>
                                <p className = "font-color-light-black parsed-html" style = {{backgroundColor: 'transparent'}}>{ReactHtmlParser(projectData[selectedProject]?.project_detail?.detail ? projectData[selectedProject]?.project_detail?.detail.replace(/<p><\/p>/g, "<p><br></p>") : "<p></p>")}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item sm = {12} md = {5} justifyContent = "flex-end">
                        <button type = "button" className = "button center-content relative cursor-pointer" id = "homepageone" onClick = {openProject}>
                            <p className = "main-font-semi-bold font-size-16 font-color-white">Meer informatie</p>
                            <img src = {require('../../../../assets/images/icon/arrow_next_white.svg')} alt = "arrow next" className = "button-long-icon" id = "homepageone" />
                        </button>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}
