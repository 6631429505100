import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Grid, Hidden, Container } from '@material-ui/core';
import { get, concat } from 'lodash';

import Spinner from '../../components/spinner/Spinner';
import OnePhotoLayout from './photo-section/OnePhotoLayout.view';
import TwoPhotosLayout from './photo-section/TwoPhotosLayout.view';
import FourPhotosLayout from './photo-section/FourPhotosLayout.view';
import FivePhotosLayout from './photo-section/FivePhotosLayout.view';
import ThreePhotosLayout from './photo-section/ThreePhotosLayout.view';

import imageOne from '../../assets/images/placeholder/no-photo.png';

import { getReferencesDetails } from './references.api';
import WindowDimensions from './WindowDimensions';
import { assetsUrl } from '../../common/api/routes';

const ReferencesPages = () => {
  const { id } = useParams();
  // const { topHeading } = ReferencesPages.styles;
  const { width } = WindowDimensions();
  const history = useHistory();

  const [isFetching, setIsFetching] = useState(false);
  const [referenceDetails, setReferenceDetails] = useState([]);
  const [referencesMainPic, setReferencedMainPic] = useState();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (id) {
      getReferencesDetails(setIsFetching, setReferenceDetails, id);
    }
  }, []);

  useEffect(() => {
    if(referenceDetails?.projectImages && referenceDetails.projectImages?.length > 0) {
      setReferencedMainPic(assetsUrl + referenceDetails?.projectImages[0]?.image);
    }
  }, [referenceDetails])

  useEffect(() => {
    console.log({referencesMainPic})
  }, [referencesMainPic])

  const getReferenceImages = (details) => {
    const allImages = concat([getImageObject(details?.projectImages, true)], details?.projectImagesPagina);
    const imagesLength = allImages.length;
    switch (imagesLength) {
      case 1:
        return <OnePhotoLayout allImages={allImages} />;
      case 2:
        return <TwoPhotosLayout allImages={allImages} />;
      case 3:
        return <ThreePhotosLayout allImages={allImages} />;
      case 4:
        return <FourPhotosLayout allImages={allImages} />;
      case 5:
      case 6:
        return <FivePhotosLayout allImages={allImages} />;
      default:
        return <OnePhotoLayout allImages={allImages} />;
    }
  };

  function getImage(imageData, type) {
    // if type is true, then it fetches the non-reference image, if it is false, then it fetches the reference image first
    const primaryImage = imageData.find(image => (type ? !image.reference : image.reference));
    if(primaryImage) {
        return primaryImage.image;
    }
    else {
        const secondaryImage = imageData.find(image => (type ? image.reference : !image.reference));
        if(secondaryImage) {
            return secondaryImage.image;
        }
        else {
            return "";
        }
    }
  }

  function getImageObject(imageData, type) {
    // if type is true, then it fetches the non-reference image, if it is false, then it fetches the reference image first
    if(imageData && imageData.length > 0) {
      const primaryImage = imageData.find(image => (type ? !image.reference : image.reference));
      if(primaryImage) {
          return primaryImage;
      }
      else {
          const secondaryImage = imageData.find(image => (type ? image.reference : !image.reference));
          if(secondaryImage) {
              return secondaryImage;
          }
          else {
              return [];
          }
      }
    }
  }

  return (
    <Grid container style={{ background: '#f1f3f3' }}>
      {isFetching ? (
        <div className="text-center padding-80" style={{ width: '100%' }}>
          <Spinner text="Fetching Project Details..." />
        </div>
      ) : (
        <>
          <Grid container item md={12}>
            {width > 960 ? (  
              <section
                className="slanted-div ref-detail-top"
              >
                <div>
                  <Grid container className="references-page-side-margin padding-top-10 padding-bottom-10">
                    <Grid container item md={5} justifyContent="center" alignItems="center">
                      <div style={{ float: 'right', paddingRight: 100 }}>
                        <p style={{
                          color: width < 960 ? 'white' : '',
                          fontSize: 40,
                          textAlign: 'right',
                          fontWeight: 'bold',
                        }}
                        >
                          {get(referenceDetails, 'name')}
                        </p>
                        <br />
                        <div
                          style={{
                            background: '#2DA0B3',
                            float: 'right',
                            width: 200,
                            height: 5,
                          }}
                        />
                        <br />
                        <p style={{
                          fontSize: 30,
                          textAlign: 'right',
                          fontWeight: 'bold',
                          color: width < 960 ? 'white' : '',
                        }}
                        >
                          {get(referenceDetails, 'city')}
                        </p>
                      </div>
                      <button type="button" className="button center-content ref-details-back" id="homepagethree" onClick={() => history.go(-1)}>
                        <img src={require('../../assets/images/icon/arrow_previous_blue.svg')} alt="arrow next" className="button-icon" id="homepagethree" />
                      </button>
                    </Grid>

                    <Grid item md={7}>
                      <div className="image relative horizontal-center" id="homepageone">
                        {(referenceDetails?.projectImages?.length > 0 && referenceDetails?.projectImages[0]?.image) ? (
                          <img src={`${assetsUrl}${getImage(referenceDetails?.projectImages, true)}`} alt="main" className="height-auto width-auto image-main" id="homepageone" />
                        ) : (
                          <img src={require('../../assets/images/placeholder/no-photo.png')} alt="main" className="height-auto width-auto image-main" id="homepageone" />
                        )}
                        <Hidden smUp>
                          <div className="background-text-container" id="homepageone">
                            <p className="main-font-bold font-size-40 font-color-white">{get(referenceDetails, 'name')}</p>
                            <div className="bar margin-top-15" id="homepageone" />
                            <p className="main-font-semi-bold font-size-28 font-color-white margin-top-15">{get(referenceDetails, 'city')}</p>
                          </div>
                        </Hidden>
                      </div>
                      {/* <div
                        className="slanted-div2"
                        style={{
                          width: '100%',
                          height: 300,
                          marginTop: -50,
                          marginBottom: -65,
                          borderRadius: 25,
                          backgroundSize: 'cover',
                          backgroundImage: referenceDetails?.projectImages?.length
                            ? `url(${assetsUrl}${referenceDetails?.projectImages[0]?.image})`
                            : `url(${imageOne})`,
                        }}
                      /> */}
                    </Grid>
                  </Grid>
                </div>
              </section>
            ) : (
              <Container maxWidth = "lg">
                <Grid container justifyContent = "center" className = "margin-top-50 margin-bottom-50">
                  <div className="image relative horizontal-end vertical-center " id="homepageone">
                    {(referenceDetails?.projectImages?.length > 0 && referenceDetails?.projectImages[0]?.image) ? (
                      <img src={`${assetsUrl}${getImage(referenceDetails?.projectImages, true)}`} alt="main" className="height-auto width-auto image-main" id="homepageone" />
                    ) : (
                      <img src={require('../../assets/images/placeholder/no-photo.png')} alt="main" className="height-auto width-auto image-main" id="homepageone" />
                    )}
                    <button type="button" className="button center-content ref-details-back" id="homepagethree" style = {{transform: "skewY(3deg)"}} onClick={() => history.go(-1)}>
                      <img src={require('../../assets/images/icon/arrow_previous_blue.svg')} alt="arrow next" className="button-icon" id="homepagethree" />
                    </button>
                    <div className="background-text-container margin-right-25" id="homepageone" style = {{bottom: 'initial'}}>
                      <p className="main-font-bold font-size-40 font-color-white">{get(referenceDetails, 'name')}</p>
                      <div className="bar margin-top-15" id="homepageone" />
                      <p className="main-font-semi-bold font-size-28 font-color-white margin-top-15">{get(referenceDetails, 'city')}</p>
                    </div>
                  </div>
                </Grid>
              </Container>
            )}
          </Grid>

          <Grid container item md={12}>
            <Grid container className="references-page-side-margin padding-top-10 padding-bottom-10">
              <Grid item md={3} style={{ paddingBottom: 20, paddingRight: 25 }}>
                <div
                  className="margin-bottom-40"
                  style={{
                    background: '#f2f3f4',
                    width: '100%',
                  }}
                >
                  {/* <p className="font-size-18" style={{ fontWeight: 700 }}>Een overzicht van de</p>
                  <p className="font-size-24 margin-bottom-20" style={topHeading}>
                    Realisaties
                  </p> */}
                  <div className="parsed-html">
                    {ReactHtmlParser(referenceDetails?.projectDetail?.paginaBody ? referenceDetails?.projectDetail?.paginaBody.replace(/<p><\/p>/g, '<p><br></br></p>') : '<p></p>')}
                  </div>
                </div>
              </Grid>

              <Grid item md={9}>
                {getReferenceImages(referenceDetails)}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

ReferencesPages.styles = {
  topHeading: {
    fontFamily: 'Amithen',
    color: '#2fa0b4',
    paddingTop: 5,
    fontSize: 50,
  },
};

export default ReferencesPages;
