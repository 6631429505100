import * as React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const MaxWidthDialog = ({
  isOpen,
  imagePath,
  handleClose,
}) => {
  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <img
          style={{ width: '100%' }}
          alt={require('../../../assets/images/placeholder/image_one.jpg')}
          src={imagePath || require('../../../assets/images/placeholder/no-photo.png')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

MaxWidthDialog.defaultProps = {
  isOpen: false,
};

MaxWidthDialog.styles = {};

MaxWidthDialog.propTypes = {
  isOpen: PropTypes.bool,
  imagePath: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MaxWidthDialog;
