import React, { createRef, useLayoutEffect } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import './privacypolicy.scss';
import { SpaceRow } from '../../../components/space/Space';

export default function PrivacyPolicy() {
  const history = useHistory();
  const location = useLocation();
  const cookieRef = createRef(0);

  useLayoutEffect(() => {
    if(location.pathname.includes('cookies')) {
      cookieRef.current.scrollIntoView({behavior: 'smooth'})
    }
    else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <Grid container>
      <Grid container className="navstyle relative" id="privacy" xs={12} md={12} direction="column" justifyContent="center">
        <div className="background-container" id="homepageone">
          <div className="background-alt" id="homepageone" />
        </div>
        <Hidden xsDown>
          <div className="button center-content relative" id="disclaimer" onClick={() => history.go(-1)}>
            <img src={require('../../../assets/images/icon/arrow_previous_blue.svg')} alt="back" className="button-icon" id="disclaimer" />
          </div>
          <p className="navheading relative" id="privacy">
            Privacy Policy
          </p>
        </Hidden>
        <Hidden smUp>
          <Grid container direction="column">
            <div className="button center-content margin-right-23 relative" id="disclaimer" onClick={() => history.go(-1)}>
              <img src={require('../../../assets/images/icon/arrow_previous_blue.svg')} alt="back" className="button-icon" id="disclaimer" />
            </div>
            <p className="navheading relative" id="privacy">
              Privacy Policy
            </p>
          </Grid>
        </Hidden>
        <p className="navheading2 main-font-semi-bold font-size-28 font-color-black relative" id="privacy">
          Verwerkingsverantwoordelijke
        </p>
        <p className="navpara1 relative" id="privacy">
          Gaelens Bouwprojecten
          info@gaelensbouwprojecten.be
          Kortrijksesteenweg 1008 Bus 001
          B-9000 Gent
        </p>
        <p className="navpara1 relative" id="privacy">
          BTW: BE 0842.289.206
          RPR: Gent, afdeling Gent
        </p>
      </Grid>
      <Grid container>
        <p className="privacyhead" id="privacy">Algemene Verordening Gegevensbescherming</p>
        <p className="privacymain" id="privacy">
          Gaelens Bouwprojecten stelt uw interesse in haar activiteiten en uw bezoek aan haar website op prijs. Wij vinden het belangrijk dat u zich niet onzeker voelt bij het bezoeken van onze website en willen ervoor zorgen dat het duidelijk is wat er gebeurt met de persoonlijke gegevens die u aan ons ter beschikking stelt.
          <br />
          <br />
          Uw persoonlijke gegevens, inclusief uw e-mail adres, zullen als volgt worden gebruikt:
          <br />
          <br />
          • Informatie die u ons geeft, met als doel informatie van ons te verkrijgen, slaan we op om uw informatieaanvraag te kunnen uitvoeren. Deze informatie wordt niet langer bewaard dan nodig.
          <br />
          • Informatie die u ons geeft in het kader van een sollicitatie, slaan we op en behandelen we met dat doel voor ogen met uiterste zorgvuldigheid. Deze informatie wordt niet langer bewaard dan nodig.
          <br />
          • De opgeslagen informatie zullen we ook aanwenden om via bezoekersanalyses onze website verder te optimaliseren
          <br />
          • Slechts wanneer u Gaelens Bouwprojecten op uitdrukkelijke wijze toestemming daartoe verleent, worden uw persoonsgegevens verwerkt voor direct marketingdoeleinden. U kan zich te allen tijde kosteloos verzetten tegen het gebruik van uw persoonsgegevens voor direct marketingdoeleinden. U richt daartoe een eenvoudig verzoek tot bovenstaande contactpersoon.
          <br />
          • We zullen uw persoonlijke informatie niet ter beschikking stellen van of verkopen aan derden, die geen onderdeel of directe partner van Gaelens Bouwprojecten  zijn.
          <br />
          <br />
          <br />
          Onze website bevat links naar niet-Gaelens Bouwprojecten websites. Gaelens Bouwprojecten  is niet verantwoordelijk voor de manier waarop in deze websites met de persoonlijke gegevens wordt omgegaan.
          <br />
          <br />
          De Europese Algemene Verordening Gegevensbescherming van 27 april 2016 (hierna kortweg ‘AVG’) legt de natuurlijke persoon of de rechtspersoon die het doel van en de middelen voor de verwerking van persoonsgegevens vaststelt (de verwerkingsverantwoordelijke), de verplichting op de natuurlijke personen wiens persoonsgegevens worden verwerkt (de betrokkenen), te informeren
        </p>
      </Grid>
      <Grid container alignItems="flex-start" className="grid-padding">
        <Grid container xs={12} md={6}>
          <Grid container item xs={12} md={11}>
            <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Uw rechten</p>
            <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">
              Als bezoeker hee u recht op inzage en toegang, recht op verbetering, wissing en beperking, recht van bezwaar, recht op het intrekken van toestemming, recht op overdraagbaarheid. Neem in dat geval contact op via bovenstaande contactgegevens.
              <br />
              <br />
              De verwerking van persoonsgegevens door Gaelens Bouwprojecten gebeurt niet op basis van geautomatiseerde beslissingen, met andere woorden niet zonder enige menselijke tussenkomst.
              <br />
              <br />
              Gaelens Bouwprojecten doet niet aan profiling op basis van de beschikbare persoonsgegevens.
            </p>
          </Grid>
        </Grid>
        <Hidden smUp>
          <SpaceRow top="50" />
        </Hidden>
        <Grid container xs={12} md={6} direction="column">
          <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Klachten</p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">
            Indien u meent dat uw rechten als betrokkene worden geschaad bij de verwerking van uw persoonsgegevens, kunt u een klacht indienen bij de
          </p>
          <p className="main-font-bold font-size-18 font-color-black privacypara3" id="privacy">
            {' '}
            <br />
            <br />
            Gegevensbeschermingsautoriteit:
          </p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara3" id="privacy">Drukpersstraat 35</p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara3" id="privacy">1000 Brussel</p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara3" id="privacy">e-mail: commission@privacycommission.be</p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara3" id="privacy">tel. +32 (0)2 274 48 00</p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara3" id="privacy">fax +32 (0)2 274 48 35</p>
        </Grid>
      </Grid>
      <SpaceRow top="50" />
      <Grid container xs={12} md={12} className="privacycookie" id="privacy">
        <p className="privacycookietext" id="privacy" ref = {cookieRef}>Cookiebeleid</p>
      </Grid>
      <p className="privacypara2" id="privacy">
        Om onze websites beter af te kunnen stemmen op uw behoeften en voorkeuren, maakt Gaelens Bouwprojecten gebruik van cookies. U heeft de mogelijkheid om de cookies te weigeren. In dat geval maken we u er attent op dat bepaalde onderdelen van onze websites niet naar behoren kunnen functioneren. Het kan ook zijn dat u geen toegang meer zal hebben tot bepaalde diensten die wij op onze website aanbieden.
        Hieronder geven wij u graag meer informatie over cookies aan de hand van een eenvoudige Q&A structuur.
      </p>
      <Hidden smUp>
        <SpaceRow top="50" />
      </Hidden>
      <Grid container alignItems="flex-start" className="grid-padding">
        <Grid container xs={12} md={6}>
          <Grid container xs={12} md={11}>
            <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Wat is een cookie?</p>
            <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">Een cookie is een tekstbestand dat in de webbrowser van uw computer of uw mobiel toestel - zoals uw Smartphone, iPhone, iPad en dergelijke - nagelaten wordt door de server van de website die u op dat moment raadpleegt. Een cookie bevat een code die alleen gelezen kan worden door de server die het er geplaatst heeft. De server heeft dus geen toegang tot andere informatie die zich bevindt op uw computer of uw mobiel toestel.</p>
          </Grid>
        </Grid>
        <Hidden smUp>
          <SpaceRow top="50" />
        </Hidden>
        <Grid container xs={12} md={6} direction="column">
          <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">
            Hoe is een cookie bestand opgebouwd?
          </p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">
            Een cookiebestand bestaat uit:
            <br />
            <br />
          </p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara3" id="privacy">• De naam van de server die het cookie bestand geplaatst heeft</p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara3" id="privacy">• Er wordt een vervaldatum meegegeven</p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara3" id="privacy">• Een unieke cijfercode</p>
        </Grid>
      </Grid>
      <SpaceRow top="50" />
      <Grid container alignItems="flex-start" className="grid-padding">
        <Grid container xs={12} md={6}>
          <Grid container xs={12} sm={11}>
            <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Wat is het nut van een cookie?</p>
            <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">
              Aan de hand van cookies zorgen we ervoor dat uw surfervaring als bezoeker op èèn van onze websites zo optimaal mogelijk ervaren wordt. Dit onder andere om u sneller en makkelijker te laten navigeren tussen de verschillende onderdelen van de website of door het aanpassen van de inhoud van onze site aan uw persoonlijke voorkeuren bvb taalvoorkeur.
            </p>
          </Grid>
        </Grid>
        <Hidden smUp>
          <SpaceRow top="50" />
        </Hidden>
        <Grid container xs={12} md={6}>
          <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Waar kan ik deze cookies terugvinden?</p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">Cookies worden in een afzonderlijke folder van uw browser op uw computer of uw mobiel toestel geplaatst.</p>
        </Grid>
      </Grid>
      <SpaceRow top="50" />
      <Grid container alignItems="flex-start" className="grid-padding">
        <Grid container xs={12} md={6}>
          <Grid container xs={12} sm={11}>
            <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Wat is een Session cookie'?</p>
            <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">
              Deze cookie heeft als functie om uw browser te herkennen gedurende het bezoek aan onze websites.
            </p>
          </Grid>
        </Grid>
        <Hidden smUp>
          <SpaceRow top="50" />
        </Hidden>
        <Grid container xs={12} md={6}>
          <p className="main-font-semi-bold font-size-28 font-color-blac privacyheading" id="privacy">Wat is een 'Permanente cookie'?</p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">Deze cookie heeft als functie om uw browser te herkennen met het oog op herhaalde bezoeken aan onze websites.</p>
        </Grid>
      </Grid>
      <SpaceRow top="50" />
      <Grid container alignItems="flex-start" className="grid-padding">
        <Grid container xs={12} md={6}>
          <Grid container xs={12} sm={11}>
            <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Welke cookies zijn noodzakelijk?</p>
            <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">
              U zal de indruk krijgen dat bepaalde onderdelen van onze websites niet of niet naar behoren functioneren indien u de noodzakelijke cookies uitschakelde. Gaelens Bouwprojecten gebruikt dergelijke cookies bijvoorbeeld bij het invullen van contactformulieren of om mee te kunnen discussiëren op onze blogs. Gaelens Bouwprojecten maakt gebruik van noodzakelijke' cookies wanneer u wenst in te loggen op uw persoonlijke account. Aan de hand van deze cookies kunnen wij op een veilige manier uw identiteit controleren om zo toegang te verlenen tot de persoonlijke informatie die u achterliet.
            </p>
          </Grid>
        </Grid>
        <Hidden smUp>
          <SpaceRow top="50" />
        </Hidden>
        <Grid container xs={12} md={6}>
          <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Wat zijn 'Functionele cookies'? </p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">Zoals het woord zelf suggereert, zorgen deze cookies voor het optimaal functioneren van onze websites zodat u een zo aangenaam en persoonlijk mogelijk bezoek aan onze websites ervaart. Gaelens Bouwprojecten maakt gebruik van Functionele cookies' voor het herinneren' van uw persoonlijke voorkeuren en voor het vermijden van eenzelfde communicatie bij herhaalde bezoeken bijvoorbeeld bij het deelnemen aan een actie.</p>
        </Grid>
      </Grid>
      <SpaceRow top="50" />
      <Grid container alignItems="flex-start" className="grid-padding">
        <Grid container xs={12} md={6}>
          <Grid container xs={12} sm={11}>
            <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">WeWat zijn 'Performantie cookies'?</p>
            <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">
              Gaelens Bouwprojecten maakt gebruik van Performantie cookies' om na te gaan hoe u onze websites gebruikt met het oog op het aanpassen en verbeteren van de inhoud en het gebruiksgemak van onze websites. Zo worden bijvoorbeeld het aantal bezoekers per pagina bijgehouden of de meest voorkomende taal waarin de website bezocht wordt.
            </p>
          </Grid>
        </Grid>
        <Hidden smUp>
          <SpaceRow top="50" />
        </Hidden>
        <Grid container xs={12} md={6}>
          <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Wat zijn 'Third Party cookies'? </p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">Dat zijn cookies die weggeschreven worden vanuit advertenties die door derden op de websites van Gaelens Bouwprojecten vermeld worden. Dit met als doel om de advertenties en/of de inhoud van de advertenties aan uw persoonlijke voorkeuren aan te kunnen passen. U krijgt deze cookies uitsluitend te zien indien u deze niet uitschakelde. Bij het gebruik van third partie cookies beschikt noch Gaelens Bouwprojecten, beschikken noch de adverteerders (derden) over uw persoonlijke gegevens.</p>
        </Grid>
      </Grid>
      <SpaceRow top="50" />
      <Grid container alignItems="flex-start" className="grid-padding">
        <Grid container xs={12} md={6}>
          <Grid container xs={12} sm={11}>
            <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Wat is 'Embedded content'?</p>
            <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">
              Gaelens Bouwprojecten kan op haar websites doorverwijzen naar andere sites via een aanklikbare link. Zo komt u op een website van een andere organisatie terecht. Het kan zijn dat de website naar waar verwezen wordt ook gebruik maakt van cookies. Gaelens Bouwprojecten adviseert u om op dat moment de gebruiksvoorwaarden van de betreffende organisatie te consulteren, vermeld op hun eigen website, om u te informeren omtrent hun privacy en cookie beleid.
            </p>
          </Grid>
        </Grid>
        <Hidden smUp>
          <SpaceRow top="50" />
        </Hidden>
        <Grid container xs={12} md={6}>
          <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Hoe kan ik de cookies beheren? </p>
          <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">Het beheren van cookies heeft u zelf in de hand. U kan de instellingen van uw browser aanpassen naar uw persoonlijke voorkeuren. U kan steeds zowel reeds geïnstalleerde cookies verwijderen als nieuwe cookies weigeren door uw browserinstellingen aan te passen.</p>
        </Grid>
      </Grid>
      <SpaceRow top="50" />
      <Grid container alignItems="flex-start" className="grid-padding">
        <Grid container xs={12} md={6}>
          <Grid container xs={12} sm={11}>
            <p className="main-font-semi-bold font-size-28 font-color-black privacyheading" id="privacy">Google Analytics cookies</p>
            <p className="main-font-semi-bold font-size-18 font-color-black privacypara" id="privacy">
              Deze website maakt gebruik van Google Analytics, één van de meest gekende alsook vertrouwde oplossing voor analytische gegevensverzameling op het internet. Deze helpt ons te begrijpen hoe u gebruik maakt van de website op een manier waarop bij bijgevolg verbeteringen kunnen aanbrengen. Deze cookies kunnen bijvoorbeeld bijhouden hoe lang u zich op onze website bevindt alsook de pagina's die u bezoekt.
              <br />
              <br />
              Voor meer informatie over Google Analytics kan u de officiële pagina bezoeken.
            </p>
            <Hidden xsDown>
              <a href="https://policies.google.com/technologies/cookies" className="main-font-semi-bold font-size-18 font-color-blue">https://policies.google.com/technologies/cookies</a>
            </Hidden>
            <Hidden smUp>
              <a href="https://policies.google.com/technologies/cookies" className="main-font-regular font-size-14 font-color-blue">https://policies.google.com/technologies/cookies</a>
            </Hidden>
          </Grid>
        </Grid>
        <Grid container xs={12} md={6} />
      </Grid>
      <SpaceRow top="50" />
    </Grid>
  );
}
