import React, { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import "./_homepage_three.scss";
import { SpaceRow } from "../../../../components/space/Space";
import { assetsUrl } from '../../../../common/api/routes'

export default function HomepageThree(props) {
    const [ hoverState, setHoverState ] = useState(0);

    const { lastProjectData } = props;
    const history = useHistory()

    const openProject = index => {
        if(lastProjectData[index]?.project_detail?.link) {
            window.open(lastProjectData[index]?.project_detail?.link_detail)
        }
        else {
            history.push("/references/" + lastProjectData[index]?.id)
        }
    }

    function getImage(imageData, type) {
        // if type is true, then it fetches the non-reference image, if it is false, then it fetches the reference image first
        const primaryImage = imageData.find(image => (type ? !image.reference : image.reference));
        if(primaryImage) {
            return primaryImage.image;
        }
        else {
            const secondaryImage = imageData.find(image => (type ? image.reference : !image.reference));
            if(secondaryImage) {
                return secondaryImage.image;
            }
            else {
                return "";
            }
        }
    }

    return(
        <Grid container>
            <Grid container className = "homepage-mobile-padding">
                <div className = "bar-container" id = "homepagethree">
                    <div className = "bar" id = "homepagethree">
                        <p className = "main-font-semi-bold font-size-18 font-color-black">Een overzicht van onze laatste</p>
                        <p className = "main-font-regular font-size-50 font-color-blue custom-font">Realisaties</p>
                    </div>
                </div>
            </Grid>
            <Hidden mdUp>
                <SpaceRow top = "25" />
            </Hidden>
            <Grid container justifyContent = "center" className = "homepage-mobile-padding">
                <div className = "container horizontal-between" id = "homepagethree">
                    <div className = "flex-column card-column" id = "homepagethree" style = {{transform: window.screen.width < 769 ? 'translateY(0px)' : 'translateY(50px)'}}>
                        {lastProjectData && lastProjectData.length > 0 && lastProjectData[0] && (
                            <div className = "card-container relative" id = "homepagethree" onMouseOver = {() => setHoverState(1)} onMouseLeave = {() => setHoverState(0)} onClick = {() => openProject(0)}>
                                <div className = "card-layer" id = "homepagethree" />
                                {lastProjectData[0]?.project_images && lastProjectData[0]?.project_images.length > 0 ? (
                                    <img src = {`${assetsUrl}${getImage(lastProjectData[0]?.project_images, false)}`} alt = "main" className = "card-image-layer" id = "homepagethree" />
                                ) : (
                                    <img src = {require('../../../../assets/images/placeholder/image_two.jpg')} alt = "image" className = "card-image-layer" id = "homepagethree" />
                                )}
                                <div className = "flex-column relative">
                                    <p className = "main-font-regular font-size-28 font-color-white">{lastProjectData[0].name}</p>
                                    <div className = "card-bar margin-top-10" id = "homepagethree" />
                                    <p className = "main-font-regular font-size-18 font-color-white margin-top-10">{lastProjectData[0].city}</p>
                                </div>
                                <button type = "button" className = "button center-content animatable-400 ease-in-out-quart" id = "homepagethree" style = {{opacity: hoverState === 1 ? 1 : 0, transform: hoverState === 1 ? 'translateX(0px)' : 'translateX(15px)'}}>
                                    <img src = {require('../../../../assets/images/icon/arrow_next_blue.svg')} alt = "arrow next" className = "button-icon" id = "homepagethree" />
                                </button>
                            </div>
                        )}
                        {lastProjectData && lastProjectData.length > 0 && lastProjectData[2] && (
                            <div className = "card-container relative margin-top-26" id = "homepagethree" onMouseOver = {() => setHoverState(2)} onMouseLeave = {() => setHoverState(0)} onClick = {() => openProject(2)}>
                                <div className = "card-layer" id = "homepagethree" />
                                {lastProjectData[2]?.project_images && lastProjectData[2]?.project_images.length > 0 ? (
                                    <img src = {`${assetsUrl}${getImage(lastProjectData[2]?.project_images, false)}`} alt = "main" className = "card-image-layer" id = "homepagethree" />
                                ) : (
                                    <img src = {require('../../../../assets/images/placeholder/image_two.jpg')} alt = "image" className = "card-image-layer" id = "homepagethree" />
                                )}
                                <div className = "flex-column relative">
                                    <p className = "main-font-regular font-size-28 font-color-white">{lastProjectData[2]?.name || ""}</p>
                                    <div className = "card-bar margin-top-10" id = "homepagethree" />
                                    <p className = "main-font-regular font-size-18 font-color-white margin-top-10">{lastProjectData[2]?.city || ""}</p>
                                </div>
                                <button type = "button" className = "button center-content animatable-400 ease-in-out-quart" id = "homepagethree" style = {{opacity: hoverState === 2 ? 1 : 0, transform: hoverState === 2 ? 'translateX(0px)' : 'translateX(15px)'}}>
                                    <img src = {require('../../../../assets/images/icon/arrow_next_blue.svg')} alt = "arrow next" className = "button-icon" id = "homepagethree" />
                                </button>
                            </div>
                        )}
                    </div>
                    <Hidden smUp>
                        <SpaceRow top = "25" />
                    </Hidden>
                    <div className = "flex-column card-column" id = "homepagethree" style = {{transform: window.screen.width < 769 ? 'translateY(0px)' : 'translateY(-50px)', justifyContent: (lastProjectData  && lastProjectData.length > 1) ? 'flex-start' : 'flex-end'}}>
                        {lastProjectData && lastProjectData.length > 0 && lastProjectData[1] && (
                            <div className = "card-container relative" id = "homepagethree" onMouseOver = {() => setHoverState(3)} onMouseLeave = {() => setHoverState(0)} onClick = {() => openProject(1)}>
                                <div className = "card-layer" id = "homepagethree" />
                                {lastProjectData[1]?.project_images && lastProjectData[1]?.project_images.length > 0 ? (
                                    <img src = {`${assetsUrl}${getImage(lastProjectData[1]?.project_images, false)}`} alt = "main" className = "card-image-layer" id = "homepagethree" />
                                ) : (
                                    <img src = {require('../../../../assets/images/placeholder/image_two.jpg')} alt = "image" className = "card-image-layer" id = "homepagethree" />
                                )}
                                <div className = "flex-column relative">
                                    <p className = "main-font-regular font-size-28 font-color-white">{lastProjectData[1]?.name || ""}</p>
                                    <div className = "card-bar margin-top-10" id = "homepagethree" />
                                    <p className = "main-font-regular font-size-18 font-color-white margin-top-10">{lastProjectData[1]?.city || ""}</p>
                                </div>
                                <button type = "button" className = "button center-content animatable-400 ease-in-out-quart" id = "homepagethree" style = {{opacity: hoverState === 3 ? 1 : 0, transform: hoverState === 3 ? 'translateX(0px)' : 'translateX(15px)'}}>
                                    <img src = {require('../../../../assets/images/icon/arrow_next_blue.svg')} alt = "arrow next" className = "button-icon" id = "homepagethree" />
                                </button>
                            </div>
                        )}
                        {lastProjectData && lastProjectData.length > 0 && lastProjectData[3] && (
                            <div className = "card-container relative margin-top-26" id = "homepagethree" onMouseOver = {() => setHoverState(4)} onMouseLeave = {() => setHoverState(0)} onClick = {() => openProject(3)}>
                                <div className = "card-layer" id = "homepagethree" />
                                {lastProjectData[3]?.project_images && lastProjectData[3]?.project_images.length > 0 ? (
                                    <img src = {`${assetsUrl}${getImage(lastProjectData[3]?.project_images, false)}`} alt = "main" className = "card-image-layer" id = "homepagethree" />
                                ) : (
                                    <img src = {require('../../../../assets/images/placeholder/no-photo.png')} alt = "image" className = "card-image-layer" id = "homepagethree" />
                                )}
                                <div className = "flex-column relative">
                                    <p className = "main-font-regular font-size-28 font-color-white">{lastProjectData[3]?.name || ""}</p>
                                    <div className = "card-bar margin-top-10" id = "homepagethree" />
                                    <p className = "main-font-regular font-size-18 font-color-white margin-top-10">{lastProjectData[3]?.city || ""}</p>
                                </div>
                                <button type = "button" className = "button center-content animatable-400 ease-in-out-quart" id = "homepagethree" style = {{opacity: hoverState === 4 ? 1 : 0, transform: hoverState === 4 ? 'translateX(0px)' : 'translateX(15px)'}}>
                                    <img src = {require('../../../../assets/images/icon/arrow_next_blue.svg')} alt = "arrow next" className = "button-icon" id = "homepagethree" />
                                </button>
                            </div>
                        )}
                        {(lastProjectData && lastProjectData.length > 1) ? (
                            <button type = "button" className = "button-long center-content margin-top-26" id = "homepagethree" onClick = {() => history.push("/references")}>
                                <p className = "main-font-semi-bold font-size-16 font-color-white">Referenties</p>
                                <img src = {require('../../../../assets/images/icon/arrow_next_white.svg')} alt = "arrow next" className = "button-long-icon" id = "homepagethree" />
                            </button>
                        ) : (
                            <button type = "button" className = "button-long center-content" style = {{transform: 'translateY(100px)'}} id = "homepagethree" onClick = {() => history.push("/references")}>
                                <p className = "main-font-semi-bold font-size-16 font-color-white">Referenties</p>
                                <img src = {require('../../../../assets/images/icon/arrow_next_white.svg')} alt = "arrow next" className = "button-long-icon" id = "homepagethree" />
                            </button>
                        )}
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}
